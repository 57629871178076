<template>
  <!-- Personalised For You Section -->
  <div class="container-fluid mb-5">
    <div class="row">
      <div class="col-lg-6 mx-auto mb-5">
        <p class="mb-3 text-center">
          <img
            width="400"
            src="@/assets/images/pages/loyalty-scheme/package-01.jpg"
            alt="Platinum"
            class="img-fluid"
          />
        </p>
        <h3 class="green-header-3-text text-center">Spend £8,000</h3>
        <p class="mb-3 text-center">
          <img
            width="400"
            src="@/assets/images/pages/loyalty-scheme/plat-rib.jpg"
            alt="Receive £13,000 worth"
            class="img-fluid"
          />
        </p>
        <h3
          class="green-header-3-text text-center mb-5"
          style="margin-top: -1.5rem"
        >
          of value including:
        </h3>

        <ul class="home-bullet-points-left">
          <li class="p-list-item mb-3 mb-md-0">
            42 nights master room (giving you 12 free nights)
          </li>
          <li class="p-list-item mb-3 mb-md-0">
            Complimentary health consultation
          </li>
          <li class="p-list-item mb-3 mb-md-0">15% off all treatments</li>
          <li class="p-list-item mb-3 mb-md-0">
            15% off Amchara360 health packages
          </li>
          <li class="p-list-item mb-3 mb-md-0">
            1 x complimentary treatment per stay
          </li>
          <li class="p-list-item mb-3 mb-md-0">
            6 day juice cleanse with Amchara Juicery, our new juice delivery
            service
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 mx-auto mb-5">
        <p class="mb-3 text-center">
          <img
            width="400"
            src="@/assets/images/pages/loyalty-scheme/package-02.jpg"
            alt="Gold"
            class="img-fluid"
          />
        </p>
        <h3 class="green-header-3-text text-center">Spend £5,000</h3>
        <p class="mb-3 text-center">
          <img
            width="400"
            src="@/assets/images/pages/loyalty-scheme/gold-rib.jpg"
            alt="Receive £7,000 worth"
            class="img-fluid"
          />
        </p>
        <h3
          class="green-header-3-text text-center mb-5"
          style="margin-top: -1.5rem"
        >
          of value including:
        </h3>
        <ul class="home-bullet-points-left">
          <li class="p-list-item mb-3 mb-md-0">
            24 nights Master room (giving you 5 free nights)
          </li>
          <li class="p-list-item mb-3 mb-md-0">10% off treatments</li>
          <li class="p-list-item mb-3 mb-md-0">
            10% off Amchara360 health packages
          </li>
          <li class="p-list-item mb-3 mb-md-0">
            1 x complimentary treatment per stay
          </li>
          <li class="p-list-item mb-3 mb-md-0">
            3 day juice cleanse with Amchara Juicery, our new juice delivery
            service
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center mb-5">
        <router-link
          to="/loyalty-scheme"
          class="btn btn-orange-gradient enquire-now-button"
          >ENQUIRE NOW</router-link
        >
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 mx-auto text-center">
        <img
          width="100%"
          src="@/assets/images/pages/loyalty-scheme/Loyalty Images-01.jpg"
          alt="Loyalty Scheme"
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>