<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 mb-4 mx-auto">
        <div>
          <form
            @submit.prevent="enquire"
            autocomplete="off"
            id="investors-form"
            ref="investorsForm"
            v-if="!formSent"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-3">
                  <label for="firstNameInput">
                    First Name
                    <span style="color: red">*</span>
                  </label>
                  <input
                    type="text"
                    class="mt-1 form-control"
                    id="firstNameInput"
                    v-model="form.first_name"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="surnameInput">
                    Surname
                    <span style="color: red">*</span>
                  </label>
                  <input
                    type="text"
                    class="mt-1 form-control"
                    id="surnameInput"
                    v-model="form.last_name"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-3">
                  <label for="emailInput">
                    Email
                    <span style="color: red">*</span>
                  </label>
                  <input
                    type="email"
                    class="mt-1 form-control"
                    id="emailInput"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="telephoneInput">
                    Telephone
                    <span style="color: red">*</span>
                  </label>
                  <input
                    type="text"
                    class="mt-1 form-control"
                    id="telephoneInput"
                    v-model="form.phone"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label for="commentsTextarea">
                  Any comments?
                </label>
              </div>
              <div class="col-md-9 my-auto">
                <textarea
                  class="mt-1 form-control"
                  id="commentsTextarea"
                  v-model="form.comments"
                  style="height: 78px; resize: none"
                ></textarea>
              </div>
              <div class="col-md-3 my-5 my-md-auto">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-callback="onSubmit"
                  style="padding: 10px 40px"
                  :disabled="!canSubmit || submitting"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>

          <div class="row" v-if="formSent">
            <div class="col text-center">
              <h4 class="mt-5 text-primary">
                Thank you for submitting, one of the founders will be in touch with you soon.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
  props: ["source"],
  data() {
    return {
      formSent: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        comments: "",
        source: this.source ? this.source : "web_form",
      },
      canSubmit: false,
      submitting: false
    };
  },
  methods: {
    enquire() {
      this.submitting = true;

      this.$axios.post(process.env.VUE_APP_API_URL + "/enquiries/investor-enquiry", this.form).then(({ data }) => {
        if (data.message == "enquired") {
          this.formSent = true;

          this.$emit("submitted");
          this.$refs.investorsForm.classList.add("d-none");
        } else {
          this.$router.push("/");
        }
      });
    },
  },
  mounted() {},
  watch: {
    form: {
      handler: function (val) {
        if (
          val.first_name.length > 1 &&
          val.last_name.length > 1 &&
          val.email.length > 5 &&
          val.phone.length > 7
        ) {
          this.canSubmit = true;
        } else {
          this.canSubmit = false;
        }
      },
      deep: true,
    },
  },
  components: {
    VueRecaptcha,
  },
};
</script>

<style>
</style>