<template>
  <div>
    <div
      class="hero-image"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
      id="slider-1"
    >
      <div class="hero-text">
        <h1>Invest in the future of wellness</h1>
      </div>
    </div>

    <featured-in></featured-in>

    <div class="body-content mx-auto py-2">
      <!-- Kickstart Your Helth / Who Our Retreats Are For Section -->
      <div class="container-fluid" ref="intro">
        <div class="row">
          <div class="d-flex flex-column align-items-center col-12 my-4 text-center responsive">
            <h4 class="text-green mb-4 responsive" style="font-weight: bold;">
              We are seeking an experienced, high-net-worth investor to partner with us,
              to develop an international wellness-spa concept, starting with Amchara Gozo.
            </h4>
            <p class="mb-4 responsive" style="width: 70%;">
              We have secured the support of a well-known, influential celebrity who is a regular
              client of Amchara and is happy to partner with us to provide extensive PR.
            </p>
            <p class="mb-4 responsive" style="width: 70%; font-weight: bold;">
              If you are interested to learn more then please submit the form below and one
              of our founders will be in touch with further information.
            </p>
          </div>
        </div>
      </div>

      <investors-form
        @submitted="submitted"
        :source="'investors_form'"
        class="mb-5"
      ></investors-form>

    </div>
  </div>
</template>

<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import Loyalty from "./page-partials/Loyalty";
import Juicery from "./page-partials/Juicery";
import FeaturedMags from "./page-partials/FeaturedMags";
import Locations from "./page-partials/Locations";
import TripAdvisor from "./page-partials/TripAdvisor";
import Testimonials from "./page-partials/Testimonials";
import Instagram from "./page-partials/Instagram";
import NewsletterCta from "./page-partials/NewsletterCta";
import InvestorsForm from "./page-partials/InvestorsForm";

export default {
  // metaInfo() {
  //   return {
  //     vmid: this.$route.path,
  //     name: this.$route.path,
  //     title: this.$store.seo_metas.find(
  //       (i) => i.slug == this.$route.path.replace("/", "")
  //     ).title,
  //     content: this.$store.seo_metas.find(
  //       (i) => i.slug == this.$route.path.replace("/", "")
  //     ).description,
  //     // titleTemplate: null,
  //   };
  // },
  data() {
    return {
      blogPosts: [],
      bgImages: [require("@/assets/images/banners/investors-banner.jpg")],
    };
  },
  mounted() {
    // function changeTheSlider(){
    // if($('#slider-1').is(":visible")){
    //     console.log('yup')
    //     $('#slider-1').slideUp()
    //     $('#slider-2').slideDown()
    //     return true;
    // }
    // if($('#slider-2').is(":visible")){
    //     $('#slider-2').slideUp()
    //     $('#slider-1').slideDown()
    //     return true;
    // }
    // }
    // window.setInterval(function(){
    //   /// call your function here
    //   changeTheSlider()
    // }, 6000);
  },
  methods: {
    submitted() {
      this.$refs.intro.classList.add("d-none");
    }
  },
  components: {
    FeaturedIn,
    Loyalty,
    Juicery,
    FeaturedMags,
    Locations,
    TripAdvisor,
    Testimonials,
    Instagram,
    NewsletterCta,
    InvestorsForm,
  },
};
</script>

<style scoped>
  @media (max-width: 45em) {
    .responsive {
      margin: 1rem 0 !important;
    }
  }
</style>